/* ========================================================================
 * Custom Added JS
 *
 * ======================================================================== */

(function($) {


	// Main mobile menu
	$('.primary-nav').slimmenu({
    resizeWidth: '1200',
    collapserTitle: '<span class="menu-text">Menu</span>',
    animSpeed: 'fast',
    collapseIcon:	'<i class="fa fa-angle-up"></i>',
    expandIcon:	'<i class="fa fa-angle-down"></i>',
	});

	// Secondary mobil menu
	$('.secondary-nav').slimmenu({
    resizeWidth: '767',
		collapserTitle: '<span class="secondary-collapse">Chapters</span>',
    animSpeed: 'fast',
	});

   $( window ).resize(function() {
      if ($(window).width() < 1200) {
         $( ".slimmenu.primary-nav" ).css("display", "none");
      }
   });

	// home page ajax taxonomy select
	$('#news-selector__terms a').on('click', function(e){
	    e.preventDefault();
	    var new_class = $(this).attr('id');
	    console.log(new_class);
	    $('#news-selector__results').removeClass();
	    $('#news-selector__results').addClass(new_class);
		$(this).parent().addClass('active').siblings().removeClass('active');
	});

	//Owl Carousels
	$('.slide-show').owlCarousel({ // main slide show
	    loop:true,
	    margin:0,
	    nav:true,
	    autoplay:true,
      navText:['&#xf053;','&#xf054;','x','y'],
	    //navText:['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>','x','y'],
	    responsive:{
	        0:{
	            items:1
	        }
	    }
	});

	$('.carousel').owlCarousel({ // home page carousel
	    loop:true,
	    margin:20,
	    nav:true,
	    //autoplay:true,
	    //navText:['','','',''],
	    //navText:['<i class="fa fa-angle-left"></i>','<i class="fa fa-angle-right"></i>','x','y'],
      navText:['&#xf053;','&#xf054;','x','y'],
	    responsive:{
	        0:{
	            items:1
	        },
	        768:{
	            items:4
	        }
      }
  });


  /**
   * Add 'active' class to current chapter in chapter menu
   * Order of these items in the menu matters!
   *  Site 1 = CA site
   *  Site 2 = LA site
   *  Site 3 = SFBA site
   *  Site 4 = SV site
   *  Site 5 = SD site
   */
  var location = window.location.href;
  var site = 1; // California Site (default)
  if(location.indexOf("losangeles") > -1) { // LA Site
    site = 2;
  } else if (location.indexOf("sfba") > -1) { // SF Bay Area Site
    site = 3;
  } else if (location.indexOf("sacval") > -1) { // Sac Val Site
    site = 4;
  } else if (location.indexOf("sandiego") > -1) { // San Diego Site
    site = 5;
  }
  // Add class
  $('#menu-chapters li:nth-of-type('+ site +')').addClass('active');

  if(site == 1) {
    //Hide campaign section on CA state home page
    $('.home-section-wrap--4').addClass('hide-on-site-' + site);

  }

  // Localhost overrides
  if((location.indexOf("docker") > -1) || (location.indexOf("localhost") > -1)) { // Localhost
    $('body').addClass('localhost');
  }


  /**
   * Sidebar accordion
   *
   */
    $(".sidebar-menu > li > .sub-menu > .menu-item-has-children > a").on("click", function(e) {
      if ($(this).siblings('.sub-menu').is(":visible")) { // submenu is visible, treat link as link
        console.log('visible true');
      } else { // submenu is not visible, treat link as toggle
        console.log('visible false');
        e.preventDefault();
        $('.sidebar-menu > li > .sub-menu > .menu-item-has-children .sub-menu').stop(true, true).slideUp(200,'linear');
        $(this).siblings('.sub-menu').stop(true, true).slideDown(200,'linear');
        $('.sidebar-menu > li > .sub-menu > .menu-item-has-children').removeClass('open-sidemenu');
        $(this).addClass('open-sidemenu');
      }

    });

  /**
   * Add placeholder text to Constant Contact forms
   *  Wait for element to exist before adding a placeholder
   */

    if((site == 1) || (site == 3) || (site == 4)) {
      var checkExist = setInterval(function() {
        if ($('#constant-contact input[type=email]').length) {
           $('#constant-contact input[type=email]').attr("placeholder","Enter email");
           $('#constant-contact input[type=text]').attr("placeholder","Name");
           clearInterval(checkExist);
        }
      }, 100); // check every 100ms
    } // end if CA site, SF site, or SV site


  /**
   * Cookies that control the pop-up
   *
   */
  console.log('cookie begins');

  var seen_popup_recently = Cookies.get('popup');
  console.log(seen_popup_recently);
  if(!seen_popup_recently) {
    Cookies.set('popup', '1', { expires: 30 });
    $('#popup').modal('toggle');
    console.log('triggered');
  }
  console.log('cookie ends');

  /**
   * Auto form-filler
   *
   */
  function getUrlVars()
  {
      var vars = [], hash;
      var hashes = window.location.href.slice(window.location.href.indexOf('?') + 1).split('&');

      for(var i = 0; i < hashes.length; i++)
          {
          hash = hashes[i].split('=');
          vars.push(hash[0]);
          vars[hash[0]] = hash[1];
          }

      return vars;
  }

  var get = getUrlVars();
  console.log('---');
  console.log(get);
  console.log(get['recurring']);
  // Donation amount
  if(get['give_amount']) {
    console.log('Amount: ');
    console.log(get['give_amount']);
    console.log(' | ');
    console.log(document.getElementById('give-amount').value);
    document.getElementById('give-amount').value = get['give_amount'];
    console.log(' # ');
  }
  // Recurring donation
  if(get['recurring'] == 'true') {
    console.log('Recurring: ');
    console.log(get['recurring']);
    console.log(' | ');
    document.getElementById('give-admin_choice-4725').checked = true;
    console.log(document.getElementById('give-admin_choice-4725').value);

    console.log(' # ');
  }
  // Donation Types
    if(get['donation_type'] == 'general_operations') {
    console.log('Type 1: ');
    console.log(get['donation_type']);
    console.log(' | ');
    document.getElementById('ffm-donation_type').value = "General Operations";
    console.log(document.getElementById('ffm-donation_type').value);
    console.log(' # ');
  }
  if(get['donation_type'] == 'civil_rights') {
    console.log('Type 2: ');
    console.log(get['donation_type']);
    console.log(' | ');
    document.getElementById('ffm-donation_type').value = "Civil Rights";
    console.log(document.getElementById('ffm-donation_type').value);
    console.log(' # ');
  }
  if(get['donation_type'] == 'immigrants_rights') {
    console.log('Type 3: ');
    console.log(get['donation_type']);
    console.log(' | ');
    document.getElementById('ffm-donation_type').value = "Immigrants' Rights";
    console.log(document.getElementById('ffm-donation_type').value);
    console.log(' # ');
  }
  if(get['donation_type'] == 'zakat') {
    console.log('Type 4: ');
    console.log(get['donation_type']);
    console.log(' | ');
    document.getElementById('ffm-donation_type').value = "Zakat";
    console.log(document.getElementById('ffm-donation_type').value);
    console.log(' # ');

  }
  // End form filler


})(jQuery); // Fully reference jQuery after this point.

